import React, { Fragment } from "react";

const ProtectedRoute = (props) => {

    return (
        <Fragment>
            {
                props.children
            }
        </Fragment>
    );
}

export default ProtectedRoute;
