import {createAsyncThunk} from "@reduxjs/toolkit";
import {useHttp} from "../../hooks/http.hook";

export const fetchNavMenu = createAsyncThunk(
    'navMenu/fetchNavMenu',
    async () => {
        const {request} = useHttp();
        const dataLang = localStorage.getItem('dataLang');
        const shortNameParam = dataLang ? `?lang=${JSON.parse(dataLang).shortName}` : '';
        const { json } = await request(`api/getMenu/${window.env.PROJECT_ID}${shortNameParam}`, 'GET');
        return await new Promise((resolve) => resolve(json))
    }
);

export const extraReducers = (builder) => {
    builder
        .addCase(fetchNavMenu.pending, state => {
            state.loadingStatus = 'loading'
        })

        .addCase(fetchNavMenu.fulfilled, (state, action) => {
            state.loadingStatus = 'loaded';
            state.menu = action.payload.data.menu;
        })

        .addCase(fetchNavMenu.rejected, state => {
            state.loadingStatus = 'error';
        })
        .addDefaultCase(() => {})
}