import {createAsyncThunk} from "@reduxjs/toolkit";
import {useHttp} from "../../hooks/http.hook";

export const fetchCTranslationKeys = createAsyncThunk(
    'translationKeys/fetchCTranslationKeys',
    async () => {
        const {request} = useHttp();

        const dataLang = localStorage.getItem('dataLang');
        const shortNameParam = dataLang ? `?lang=${JSON.parse(dataLang).shortName}` : '';

        const {json} = await request(`api/get-translations/${window.env.PROJECT_ID}${shortNameParam}`, 'GET');

        return await new Promise((resolve) => resolve(json));
    }
);