import {createAsyncThunk} from "@reduxjs/toolkit";
import {useHttp} from "../../hooks/http.hook";



export const fetchQuotes = createAsyncThunk(
    'quotes/fetchQuotes',
    async (nameTab, { getState }) => {
        const {request} = useHttp();

        if (getState().quotes && getState().quotes.loadingStatus === 'loaded') {
            return getState().quotes.items;
        }

        const {json} = await request(`api/${window.env.PROJECT_ID}/quotes-json/${nameTab}`, 'GET');



        return await new Promise((resolve) => resolve(json));
    }
)


export const extraReducers = () => {
    return (builder) => {
        builder
            .addCase(fetchQuotes.pending, state => {
                state.loadingStatus = 'loading'
            })

            .addCase(fetchQuotes.fulfilled, (state, action) => {
                state.loadingStatus = 'loaded';
                state.items = action.payload;
            })

            .addCase(fetchQuotes.rejected, state => {
                state.loadingStatus = 'error';
            })
            .addDefaultCase(() => {
            })
    }
}


