import {createAsyncThunk} from "@reduxjs/toolkit";
import {useHttp} from "../../hooks/http.hook";

export const fetchComponentInfo = createAsyncThunk(
    'componentInfo/fetchComponentInfo',
    async (componentNames, {getState}) => {
        const state = getState();
        const {request} = useHttp();

        const dataLang = localStorage.getItem('dataLang');
        const shortNameParam = dataLang ? `?lang=${JSON.parse(dataLang).shortName}` : '';

        const fetchData = async (nameComponent) => {
            const {json} = await request(`api/getComponent/${window.env.PROJECT_ID}/${nameComponent}${shortNameParam}`, 'GET');
            return json;
        };

        const fetchPromises = componentNames.map(name => fetchData(name));
        const data = await Promise.all(fetchPromises);

        return data.map((component) => component.data);
    }
);