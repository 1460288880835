import {createAsyncThunk} from "@reduxjs/toolkit";
import {useHttp} from "../../hooks/http.hook";

/*
export const fetchNewsInfo = createAsyncThunk(
    'newsInfo/fetchNewsInfo',
    async (query) => {
        const {request} = useHttp();
        const dataLang = localStorage.getItem('dataLang');

        const shortNameParam = dataLang ? `?lang=${JSON.parse(dataLang).shortName}` : '';
        let url = `api/news/${window.env.PROJECT_ID}/${shortNameParam}`

            if(query.hasOwnProperty('categoryId')) {
                    url += `&categoryId=${query.categoryId}`;
            }

        const {json} = await request(url, 'GET');
        return await new Promise((resolve) => resolve(json));

    }
);*/


export const fetchNewsInfo = createAsyncThunk(
    'newsInfo/fetchNewsInfo',
    async (query) => {
        const {request} = useHttp();
        const dataLang = localStorage.getItem('dataLang');
        const shortNameParam = dataLang ? `?lang=${JSON.parse(dataLang).shortName}` : '';
        let url = `api/news/${window.env.PROJECT_ID}/${shortNameParam}`;

        if (query.hasOwnProperty('categoryId')) {
            url += `&categoryId=${query.categoryId}`;
        }
        if (query.hasOwnProperty('top')) {
            url += `&top=${query.top}`;
        }
        if (query.hasOwnProperty('skip')) {
            url += `&skip=${query.skip}`;
        }

        const {json} = await request(url, 'GET');
        return await new Promise((resolve) => resolve(json));
    }
);
