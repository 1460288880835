import {createAsyncThunk} from "@reduxjs/toolkit";
import {useHttp} from "../../hooks/http.hook";
import {pageMetaTag, pageScripts} from "../../utils/updateScripts";

export const fetchDynamicPageInfo = createAsyncThunk(
    `dynamicPageInfo/fetchDynamicPageInfo`,
    async (params) => {
        const {request} = useHttp();
        const dataLang = localStorage.getItem('dataLang');
        const shortNameParam = dataLang ? `?lang=${JSON.parse(dataLang).shortName}` : '';
        const {json} = await request(`api/getPage/${window.env.PROJECT_ID}/${params.page}${shortNameParam}`, 'GET');
        pageMetaTag(json.data)
        pageScripts(json.data);
        return await new Promise((resolve) => resolve(json));
    }
);


export const extraReducers = (builder) => {
    builder
        .addCase(fetchDynamicPageInfo.pending, state => {
            state.loadingStatus = 'loading'
        })

        .addCase(fetchDynamicPageInfo.fulfilled, (state, action) => {
            state.loadingStatus = 'loaded';
            state.items = action.payload.data;
        })

        .addCase(fetchDynamicPageInfo.rejected, state => {
            state.loadingStatus = 'error';
        })
        .addDefaultCase(() => {
        })
}