import {createSlice} from "@reduxjs/toolkit";
import {extraReducers} from "./action";


const initialState = {
    menu: [],
    loadingStatus: 'loaded',

}

const navMenuSlice = createSlice({
        name: 'navMenu',
        initialState,
        reducers: {
          /*  fetchSort: (state, action) => {
                state.sort = {};
                if (action.payload.direction !== '') {
                    state.sort[action.payload.field] = action.payload.direction
                }
            },*/

        },
        extraReducers: extraReducers
    }
);

const {actions, reducer} = navMenuSlice;

export default reducer;

export const {fetchSort} = actions;
