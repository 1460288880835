import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import Spinner from "../spinner/Spinner";
import ProtectedRoute from "../routs/ProtectedRoute"
import LocalizedRoute from "../routs/LocalizedRoute";
import React, {lazy, Suspense, useEffect} from "react";

const ErrorIpPage = lazy(() => import('../pages/ErrorIpPage' ))
const DynamicPage = lazy(() => import('../pages/PageComponent' ))

const ScrollToTop = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const App = () => {
    return (
        <Router>
            <ScrollToTop/>
            <Suspense fallback={<Spinner/>}>
                <Routes>
                    <Route path="/" element={<LocalizedRoute/>}/>
                    <Route path=":lang/" element={<LocalizedRoute/>}>
                        <Route path=":dynamicPage"
                               element={
                                   <ProtectedRoute>
                                       <DynamicPage/>
                                   </ProtectedRoute>
                               }
                        />
                        <Route path="error-ip-page"
                               element={
                                   <ProtectedRoute>
                                       <ErrorIpPage/>
                                   </ProtectedRoute>
                               }
                        />
                    </Route>
                </Routes>
            </Suspense>
        </Router>
    )
}

export default App;











