import {configureStore} from "@reduxjs/toolkit";

import navMenu from "../models/navigationMenu/slice";
import projectInfo from "../models/ProjectInfo/slice";
import ip from "../models/validateIP/slice";
import componentInfo from "../models/Components/slice";
import dynamicPageInfo from "../models/DynamicPageInfo/slice";
import quotes from "../models/Quotes/slice"
import newsInfo from "../models/News/slice";
import translationKeys from "../models/TranslationKeys/slice";

const stringMiddleware = ({dispatch, getState}) => (next) => (action) => {
    if (typeof action === 'string') {
        return next({
            type: action
        })
    }
    return next(action);
}

const store = configureStore({
    reducer: {
        navMenu,
        projectInfo,
        ip,
        componentInfo,
        dynamicPageInfo,
        quotes,
        newsInfo,
        translationKeys
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(stringMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
})


export default store;