import {createAsyncThunk} from "@reduxjs/toolkit";
import {useHttp} from "../../hooks/http.hook";

export const fetchIp = createAsyncThunk(
    'ip/fetchIp',
    async (params) => {
        const {request} = useHttp();
        const {json} = await request(`api/validate-ip/website/${window.env.PROJECT_ID}`, 'GET');
        json.is404Page = params?.is404Page

        return await new Promise((resolve) => resolve(json))
    }
);

export const extraReducers = (builder) => {
    builder
        .addCase(fetchIp.pending, state => {
            state.loadingStatus = 'loading'
        })
        .addCase(fetchIp.fulfilled, (state, action) => {
            state.loadingStatus = 'loaded';
            state.item = action.payload.data;

            if (!state.item.isValid && state.item.displayBlockType === 0) {
                state.loadingStatus = 'isBlocked';
                if (!action.payload.is404Page) {
                    window.location = '/error-ip-page';
                }
            } else if (!state.item.isValid && state.item.displayBlockType === 1) {
                state.loadingStatus = 'isBlockedPopup';
            } else {
                state.loadingStatus = 'isAllowed';
            }
        })
        .addCase(fetchIp.rejected, state => {
            state.loadingStatus = 'error';
        })
        .addDefaultCase(() => {
        })
}