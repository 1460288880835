import {createSlice} from "@reduxjs/toolkit";
import {fetchCTranslationKeys} from "./action";


const initialState = {
    items: [],
    loadingStatus: 'loaded',
}

const componentSlice = createSlice({
    name: 'translationKeys',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCTranslationKeys.pending, state => {
                state.loadingStatus = 'loading'
            })
            .addCase(fetchCTranslationKeys.fulfilled, (state, action) => {
                state.loadingStatus = 'loaded';
                state.items = action.payload.data;
            })
            .addCase(fetchCTranslationKeys.rejected, state => {
                state.loadingStatus = 'error';
            });
    }
});

export const {reducer} = componentSlice;

export default reducer;
