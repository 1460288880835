import {createSlice} from "@reduxjs/toolkit";
import {fetchNewsInfo} from "./action";


const initialState = {
    items: [],
    loadingStatus: 'loaded',
}

const newsSlice = createSlice({
    name: 'newsInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNewsInfo.pending, state => {
                state.loadingStatus = 'loading'
            })
            .addCase(fetchNewsInfo.fulfilled, (state, action) => {
                state.loadingStatus = 'loaded';
                state.items = action.payload;
            })
            .addCase(fetchNewsInfo.rejected, state => {
                state.loadingStatus = 'error';
            });
    }
});

export const {reducer} = newsSlice;

export default reducer;
