import {createSlice} from "@reduxjs/toolkit";
import {extraReducers} from "./action";

const initialState = {
    items: [],
    loadingStatus: 'loaded',
}

const quotesSlice = createSlice({
        name: 'quotes',
        initialState,
        reducers: {},
        extraReducers: extraReducers()
    }
);

const {actions, reducer} = quotesSlice;

export default reducer;

export const {} = actions;
