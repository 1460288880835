import {createSlice} from "@reduxjs/toolkit";
import {extraReducers} from "./action";

const initialState = {
    items: [],
    loadingStatus: 'loaded',
}

const dynamicSlice = createSlice({
        name: 'dynamicPageInfo',
        initialState,
        reducers: {},
        extraReducers: extraReducers
    }
);

const {actions, reducer} = dynamicSlice;

export default reducer;

export const {fetchSort} = actions;
