import {createSlice} from "@reduxjs/toolkit";
import {extraReducers} from "./action";

const initialState = {
    item: [],
    loadingStatus: 'loaded',
}

const ipSlice = createSlice({
        name: 'ip',
        initialState,
        reducers: {},
        extraReducers: extraReducers
    }
);

const {actions, reducer} = ipSlice;

export default reducer;

export const {fetchSort} = actions;
