
export const removeDataScripts = () => {
    const elementsWithDataScripts = document.querySelectorAll('[data-scripts="page-scripts"]');
    elementsWithDataScripts.forEach(element => {
        element.parentNode.removeChild(element);
    });
}

export const appendScriptToElement = (scriptString, tagElement, position, dataScripts) => {
    const tempContainer = document.createElement('html');
    tempContainer.innerHTML = scriptString;

    const scripts = tempContainer.getElementsByTagName('script')

    for (let i = 0; i < scripts.length; i++) {
        let newScriptElement = document.createElement('script');
        newScriptElement.src = scripts[i].src;
        newScriptElement.innerHTML = scripts[i].innerHTML
        newScriptElement.async = scripts[i].async

        if (dataScripts) {
            newScriptElement.setAttribute('data-scripts', dataScripts)
        }

        if (position === 'top') {
            tagElement.insertBefore(newScriptElement, tagElement.firstChild);
        } else {
            tagElement.appendChild(newScriptElement);
        }
    }
}

export const pageScripts = (json) => {
    removeDataScripts();
    const headScriptString = json.headScript;
    const bodyTopScriptString = json.bodyTopScript;
    const bodyBottomScriptString = json.bodyBottomScript;

    if (headScriptString) {
        appendScriptToElement(headScriptString, document.head, null, 'page-scripts');
    }
    if (bodyTopScriptString) {
        appendScriptToElement(bodyTopScriptString, document.body, 'top', 'page-scripts');
    }
    if (bodyBottomScriptString) {
        appendScriptToElement(bodyBottomScriptString, document.body, null, 'page-scripts');
    }
}

export const projectScripts = (json) => {
    const projectScripts = document.querySelectorAll('[data-scripts="project-script"]');
    if (!projectScripts.length) {
        json.map(item => {
            if (item.positionType === 0) {
                const headScriptStringAll = item.body;
                appendScriptToElement(headScriptStringAll, document.head, null, 'project-script');
            } else {
                const bodyScriptStringAll = item.body;
                appendScriptToElement(bodyScriptStringAll, document.body, null, 'project-script');
            }
        })
    }
}


export const pageMetaTag = (json) => {
    const titleTag = document.querySelector('title');
    titleTag.textContent = json?.title;

    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    descriptionMetaTag.setAttribute('content', json?.description);

    const canonicalUrlTag = document.querySelector('link[rel="canonical"]');
    canonicalUrlTag.setAttribute('href', json?.canonicalUrl);

    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    keywordsMetaTag.setAttribute('content', json?.keywords);
}
